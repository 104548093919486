// Font
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700;800&display=swap');
// Colors
$white: #fff;
$gray: #D0D1D2;
$grayDark: #343a40;
$primary: #2E2B63;
$secondary: #004AFF;
$success: #12CE27;
$info: #4ECDC4;
$warning: #F5C52A;
$danger: #FD1C3A;
$light: #F2F4F8;
$dark: #0B0C08;
$black: #000000;
$border: rgba($dark,
0.15);
$background: #F6F9FC;
:root {
    --gray-dark: $grayDark;
    --white: $white;
    --gray: $gray;
    --primary: $primary;
    --secondary: $secondary;
    --success: $success;
    --info: $info;
    --warning: $warning;
    --danger: $danger;
    --light: $light;
    --dark: $dark;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: 'Mulish', sans-serif;
    --font-family-body: 'Mulish', sans-serif;
    --font-family-hd: 'Montserrat', sans-serif;
    // --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --border: #DDE4EB;
}

// Variable
// $spacer: 2rem;
$enable-shadows: true;
// $grid-gutter-width: 64px;
